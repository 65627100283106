// require lib for webpack
let ClientOAuth2 = require('client-oauth2');

// import babel-polyfill to fetch async
import '../../../node_modules/babel-polyfill'

// module instance aka backend connection
let cj_OAuth2 = new ClientOAuth2({
    accessTokenUri: process.env.NEW_BACKEND_BASE_URL + '/apis/web/oauth/token',
    authorizationUri: process.env.NEW_BACKEND_BASE_URL + '/apis/web/oauth'
});

// request token and store in cookie 
export async function getAuthentification(username, password) {
    // request new tokens with credentials
    let data = cj_OAuth2.owner.getToken(username, password)
    .then(async (user) => {
        // store token in local storage to handle persistence
        localStorage.setItem('ClientOAuth2Token', JSON.stringify(user.data));
        return true;
    }, async (data) => {
        return data.body.error;
    })
    return await data;
}

// export new builded token from localStorage for api requests
export function getRequestToken() {
    // check if the current accessToken stored in localStorage is expired
    let token = cj_OAuth2.createToken(JSON.parse(localStorage.getItem('ClientOAuth2Token')));
    return token
}

// export function to refresh expired accessToken and store new accessToken
export async function refreshToken() {
    let token = cj_OAuth2.createToken(JSON.parse(localStorage.getItem('ClientOAuth2Token')));
    let data = token.refresh().then(async response => {
        localStorage.removeItem(localStorage.getItem('ClientOAuth2Token'));
        return localStorage.setItem('ClientOAuth2Token', JSON.stringify(response.data));
    });

    return data;
}
