import './../css/main.sass'
import './partials/navbar.js'

import '../../node_modules/babel-polyfill'

import 'jquery-ui/ui/widgets/datepicker.js'
import 'jquery-ui/themes/base/core.css'
import 'jquery-ui/themes/base/datepicker.css'
import 'jquery-ui/themes/base/theme.css'

import { getAuthentification } from '../js/oauth2/authenticate.js';
import Vue from 'vue/dist/vue.esm'


let store = {
    state: {
        //login
        login_mail: null,
        login_password: null,        
        login_loading: false,
        login_formData: {},

        invalid_login_mail: false,
        invalid_login_mail_error_message: null,        
        invalid_login_password: false,
        invalid_login_password_error_message: null,
        invalid_login_message: null,
        
        //forgot-password
        forgot_mail: null,
        invalid_forgot_email: false,
        invalid_forgot_email_error_message: null,
        invalid_forgot_email_request: false,
        
        //apply-for
        apply_for_cardnumber: null,
        apply_for_date_of_birth: null,
        apply_for_mail: null,
        apply_for_formData: {},
        
        invalid_apply_for_cardnumber: false,
        invalid_apply_for_cardnumber_error_message: null,
        invalid_apply_for_date: false,
        invalid_apply_for_date_error_message: null,
        invalid_apply_for_mail: false,
        invalid_apply_for_mail_error_message: null,

        responseMessage_apply_for: null,
        
        //trigger
        loading_logged_in: false,
        successfully_logged_in: false,
        
        loading_forgot_password: false,
        successfully_password_reset: false,
        
        loading_applied_for: false,
        successfully_applied_for: false,
        
        view: "login",

    }
}

let login = new Vue({
    el: '#login-content',
    data: store.state,
    methods: {
        manageView: function(target_view) {
            // this if case refers to the function being called from a button or link tag
            if(event != undefined){
                event.preventDefault();
                event.stopPropagation();
            }

            if(target_view != undefined){
                this.view = target_view;
            } else {
                target_view = this.view;
            }
            if(target_view == 'login'){
                this.resetValidationVariables('login');
                this.login_mail = null;
                this.login_password = null;
            }
            
            if(target_view == 'forgot_password'){
                this.resetValidationVariables('forgot_password');
                this.forgot_mail = null;                
            }

            if(target_view == 'sign_up'){
                this.resetValidationVariables('application');
                this.apply_for_cardnumber = null;
                this.apply_for_date_of_birth = null;
                this.apply_for_mail = null;
            }
        },

        // Methods login
        async submitLogin() {    

            // enable loading animation
            this.login_loading = true;

            // check user input from login form
            let validForm = this.checkInputLogin();

            // only continue if form is valíd
            if(validForm) {

                // get authtification token 
                let oauth = await getAuthentification(this.login_mail, this.login_password);

                this.checkLoginError(oauth);
               
                // if token is stored in loaclStorage redirect
                if(localStorage.getItem('ClientOAuth2Token') && oauth) {
                    window.location.href = 'user-dashboard.html';
                } else {
                    this.login_loading = false;        
                }

            } else {
                // end animation if request didin't went threw
                this.login_loading = false;
            }

        },

        // Methods reset password form 
        set_reset_password: function(){
            this.resetValidationVariables('forgot_password')
            let valid = this.checkInputForgotPassword()
            //TODO formData results in 400
            if(valid){
                let formData = new FormData();
                formData.append('email', this.forgot_mail);

                this.loading_forgot_password = true;

                this.sendResetPassword(formData);            


            }         
        },
        
        sendResetPassword(formData){
            fetch(process.env.NEW_BACKEND_BASE_URL + '/apis/web/v1/cashbacks/send_password_reset_email', {
                method: 'POST',                
                body: formData
            }).then(response => {
                //reject not 200(HTTP-Statuscode) response
                if(!response.ok){
                    return Promise.reject(response)
                }
                return response.json()
            })
            // catch error response
            .catch(async response => {
                const error = await response
                return Promise.reject(error)
            })
            .then(() => {
                // process data after successful fetch
               this.successfully_password_reset = true;
               this.loading_forgot_password = false;
            })
            .catch(error => 
                // process error after unsuccessful fetch
                this.handleErrorResponseForgotPassword(error)
            );            
        },

        handleErrorResponseForgotPassword(error){
            switch(error.status){                
                case 422:
                    this.invalid_forgot_email_request = true;
                    break;                
                default:
                    this.invalid_forgot_email_request = true;
                    this.invalid_forgot_email_error_message = "Leider scheint etwas schiefgelaufen zu sein. Bitte versuchen Sie es zu einem späteren Zeitpunkt erneut.";
                    break;
                
            }
            this.loading_forgot_password = false;
        },

        // Methods apply_for
        submitApplication: function () {

            // indicate loading
            this.loading_applied_for = true;

            // validate form
            let validForm = this.checkInputApplication();

            if(validForm) {
                // build api request body
                let requestBody = this.generateFormData('application');
                // call api with it
                this.sendApplication(requestBody);                       
            }

        },
        checkInputLogin: function() {

            // reset validation cariables
            this.resetValidationVariables('login');

            // check mail
            if(!this.checkMailShared(this.login_mail)){
                this.invalid_login_mail = true;
                this.invalid_login_mail_error_message = 'Bitte geben sie eine gültige E-Mail Adresse an!';
            }

            // check password
            let pw = this.login_password;
            if(pw != null) {
                if(!(pw.length > 7 && pw.length < 129)){
                       this.invalid_login_password = true;
                       this.invalid_login_password_error_message = 'Das Passwort muss min. 8-Zeichen lang sein!';
                }
            } else {
                this.invalid_login_password = true;
                this.invalid_login_password_error_message = 'Das Passwort muss min. 8-Zeichen lang sein!';
            }

            // return result of form check
            if(!this.invalid_login_mail && !this.invalid_login_mail) {
                return true;
            } else {
                return false;
            }
        },

        checkLoginError: function(source){
            switch (source){
                case 'invalid_grant':
                    this.invalid_login_message = "Die von Ihnen eingegebenen Nutzerdaten konnten leider keinem Kundenkonto zugeordnet werden."
            }

            return source;
        },

        resetLogin: function() {
            this.invalid_login_message = null;
            this.manageView("login");
        },

        checkInputForgotPassword: function() {

            // reset validation variables
            this.resetValidationVariables('forgot_password');

            // check mail
            if(!this.checkMailShared(this.forgot_mail)){
                this.invalid_forgot_email = true;
            }

            // return result of form check 
            if(!this.invalid_forgot_email){
                return true;
            } else {
                return false;
            }
        },

        checkInputApplication: function() {

            // reset validation variables 
            this.resetValidationVariables('application');

            // check cardnumber
            if(!Number.isInteger(parseInt(this.apply_for_cardnumber)) || this.apply_for_cardnumber.length != 16 ){
                this.invalid_apply_for_cardnumber = true;
                this.invalid_apply_for_cardnumber_error_message = 'Die von Ihnen eingegebene Kartennummer ist ungültig!';
                this.loading_applied_for = false;
            }

            // check if date is in the past
            if((Date.parse(this.apply_for_date_of_birth) - Date.parse(new Date())) > 0){
                this.invalid_apply_for_date = true;
                this.invalid_apply_for_date_error_message = 'Das von Ihnen eingegebene Datum muss in der Vergangenheit liegen!';
                this.loading_applied_for = false;
            }                      
            
            // check if date of birth isn't empty
            if(this.apply_for_date_of_birth == null){
                this.invalid_apply_for_date = true;
                this.invalid_apply_for_date_error_message = 'Bitte geben Sie Ihr Geburtsdatum an!';
                this.loading_applied_for = false;
            }

            // check mail
            if(!this.checkMailShared(this.apply_for_mail)){
                this.invalid_apply_for_mail = true;
                this.invalid_apply_for_mail_error_message = 'Bitte geben sie eine gültige E-Mail Adresse an!';
                this.loading_applied_for = false;
                
            }

            // return result of form check
            if(!this.invalid_apply_for_cardnumber &&
                !this.invalid_apply_for_date &&
                !this.invalid_apply_for_mail){
                return true;
            } else {
                return false;
            }
        },
        // API methods
        // api fetch for application form
        sendApplication(opts) {
            fetch(process.env.NEW_BACKEND_BASE_URL + '/apis/web/v1/cashbacks/'+ this.apply_for_cardnumber + '/apply', {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(opts)
            }).then(response => {
                //reject not 200(HTTP-Statuscode) response
                if(!response.ok){                    
                    return Promise.reject(response)
                }
                this.setSuccessfulApplication(response.json())                 
            })
            // catch error response
            .catch(async response => {
                const error = await response
                const string = await response.json()
                this.handleErrorResponseApplication(error, string)
            })
            
        },

        setSuccessfulApplication(bool){
            if(bool){
                // end loading
                this.loading_applied_for = !bool;
                // set successful response message for user
                this.responseMessage_apply_for = 'Wir konnten Ihre Kartennummer und Ihr Geburtsdatum einem bereits im System bestehenden Datensatz zuordnen und haben diesem die von Ihnen angegebene E-Mail-Adresse automatisch hinzugefügt. Zudem haben wir Ihnen an die von Ihnen angegebene E-Mail-Adresse eine E-Mail gesendet. Diese enthält einen Link, der Sie direkt in Ihren persönlichen Kundenbereich führt, um die Einrichtung Ihres digitalen Kundenkontos abzuschließen.';
            }
        },

        // Helper methods

        // function to validate a mail address
        checkMailShared: function(mail){
            let validMail = /^\S+@\S+\.\S+$/;
            if(validMail.test(mail) && mail.length > 0){
                return true;
            } else {
                return false;
            }            
        },

        // function to reset validation variables
        resetValidationVariables: function(form){
            switch(form) {
                case 'login':
                    this.invalid_login_password = false;
                    this.invalid_login_mail = false;
                    break;
                case 'forgot_password':
                    this.invalid_forgot_email = false;
                    this.invalid_forgot_email_error_message = null;
                    this.successfully_password_reset = false;
                    this.invalid_forgot_email_request = false;
                    break;
                case 'application':
                    this.invalid_apply_for_cardnumber = false;                    
                    this.invalid_apply_for_date = false;
                    this.invalid_apply_for_mail = false;  
                    break;
            }
        },

        //function to build request body 
        generateFormData(form) {
            switch(form) {
                
                //generate login formData
                case 'login':
                    this.login_formData.email = this.login_mail;
                    this.login_formData.password = this.login_password;
                    return this.login_formData;               
                
                //generate apply_for formData
                case 'application':
                    this.apply_for_formData.email = this.apply_for_mail;                     
                    let chunks = this.apply_for_date_of_birth.split('.');
                    this.apply_for_formData.date_of_birth = chunks[2] + '-' + chunks[1] + '-' + chunks[0];
                    return this.apply_for_formData;                    
            }
        },

        handleErrorResponseApplication(error, responseMessage) {
            
            switch(error.status) {
                case 401:
                    this.responseMessage_apply_for = '401 nicht authorisierter zugriff.';
                    break;
                case 422:
                    this.responseMessage_apply_for = 'Vielen Dank für Ihren Antrag, sobald Ihre Daten von der Ratingen Marketing GmbH erfasst wurden, erhalten Sie von uns eine E-Mail.';
                    break;
                case 500:
                    this.responseMessage_apply_for = 'Leider scheint etwas schiefgelaufen zu sein. Bitte versuchen Sie es zu einem späteren Zeitpunkt erneut.'
                    break;                
                default:
                    this.responseMessage_apply_for = 'Leider scheint etwas schiefgelaufen zu sein. Bitte versuchen Sie es zu einem späteren Zeitpunkt erneut.'
                    break;                
            }
            this.loading_applied_for = false;
        },

        pickApplyDate: function() {
            let that = this;
            $('#date_apply').datepicker({
                dateFormat : 'dd.mm.yy',
                onSelect: function(){
                    console.log($('#date_apply').val());
                    that.apply_for_date_of_birth = $('#date_apply').val();
                }
            }).focus();
        }
    },
    mounted: function() {
        // redirect to index 
        if(localStorage.getItem('ClientOAuth2Token') != null) {
            window.location.href = "index.html";
        }
        
        // prevent content hopping while rendering
        document.getElementById('login-content').classList.remove('opacity-0')

        // check url for parameters
        if(window.location.search == "?password_reset") {
            this.view = "forgot_password";
        }

        $('#cookie-bot-btn').unbind('click');
        $('#cookie-bot-btn').click(function() {
            $('#cookie-data-wrap').toggleClass('donot-display');
        });
          
    }
})